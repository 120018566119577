@import 'bootstrap/dist/css/bootstrap.css';
@import '@fortawesome/fontawesome-pro/css/fontawesome.css';
@import '@fortawesome/fontawesome-pro/css/light.css';

@font-face {
    font-family: 'Open Sans', sans-serif;
    src: url('../Fonts/OpenSans-Regular.woff2') format('woff2'), url('../Fonts/OpenSans-Regular.woff') format('woff');
}

:root {
    --font-family: Open Sans;
    --primary-color: #0b4f6c;
    --background-color: #eef3f7;
    --warning-color: #e19f14;
    --text-dark-color: #141414;
    --text-dark-muted-color: #909294;
    --text-light-color: #fefefe;
    --text-light-muted-color: #e6e6e6;
    --link-color: #0077cc;

    /* Bootstrap overrides */
    --bs-primary: var(--primary-color);
    --bs-primary-rgb: 11, 79, 108;
    --bs-body-font-family: var(--font-family);
    --bs-body-color: var(--text-dark-color);
    --bs-body-bg: #fefefe;
    --bs-secondary-color: var(--text-dark-muted-color);
    --bs-tertiary-color: var(--text-dark-muted-color);
    --bs-link-color: var(--link-color);
    --bs-warning: var(--warning-color);
    --bs-warning-text-emphasis: var(--warning-color);
    --bs-warning-border-subtle: var(--warning-color);
}

body {
    font-family: var(--font-family), Helvetica, Arial, sans-serif;
    background-color: var(--background-color);
}

.callout {
    --bs-link-color-rgb: var(--bs-callout-link, var(--bs-link-color-rgb));
    --bs-code-color: var(--bs-callout-code-color, var(--bs-code-color));
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    color: var(--bs-callout-color, inherit);
    background-color: var(--bs-callout-bg, var(--bs-gray-100));
    border-left: 0.25rem solid var(--bs-callout-border, var(--bs-gray-300));
    font-size: 0.875rem;
}

.callout p:last-child {
    margin-bottom: 0;
}

.callout-info {
    --bs-callout-color: var(--bs-info-text-emphasis);
    --bs-callout-bg: var(--bs-info-bg-subtle);
    --bs-callout-border: var(--bs-info-border-subtle);
}

.callout-warning {
    --bs-callout-color: var(--bs-warning-text-emphasis);
    --bs-callout-bg: var(--bs-warning-bg-subtle);
    --bs-callout-border: var(--bs-warning-border-subtle);
}

.callout-danger {
    --bs-callout-color: var(--bs-danger-text-emphasis);
    --bs-callout-bg: var(--bs-danger-bg-subtle);
    --bs-callout-border: var(--bs-danger-border-subtle);
}

.circle {
    display: flex;
    width: 50px;
    height: 50px;
    padding-top: 0.175rem;
    color: var(--primary-color);
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid var(--primary-color);
}

.footer {
    height: 4rem;
}

.footer img {
    width: 2.625rem;
    height: 2.625rem;
}

.header {
    background-image: url("../Images/website-header-background.png");
    background-position: center;
    background-size: cover;
}

.header .btn {
    --bs-btn-color: var(--primary-color);
    --bs-btn-bg: var(--background-color);
    --bs-btn-border-color: var(--primary-color);
    --bs-btn-active-color: var(--text-light-color);
    --bs-btn-active-bg: var(--primary-color);
    --bs-btn-active-border-color: var(--primary-color);
    --bs-btn-hover-color: var(--text-light-color);
    --bs-btn-hover-bg: #1490c5;
    --bs-btn-hover-border-color: var(--primary-color);
}

.header .dropdown-menu {
    --bs-dropdown-link-active-color: var(--text-light-color);
    --bs-dropdown-link-active-bg: var(--primary-color);
    --bs-dropdown-link-hover-color: var(--text-light-color);
    --bs-dropdown-link-hover-bg: #1490c5;
}

li {
    padding-top: 0.5rem;
}

/*
    Bootstrap overrides
*/
.btn {
    /* Button text is a teeny bit misaligned with Open Sans */
    padding-top: 0.5rem;
    --bs-btn-active-bg: var(--primary-color);
    --bs-btn-active-border-color: var(--primary-color);
    --bs-btn-hover-bg: #1490c5;
    --bs-btn-hover-border-color: #1490c5;
}

.btn-primary {
    --bs-btn-bg: var(--primary-color);
}

.card-title {
    margin: 0 auto 3rem auto;
    padding: 1rem 0;
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--primary-color);
    border-bottom: 3px solid var(--primary-color);
}

.card-title.card-title-warning {
    color: var(--bs-warning-text-emphasis);
    border-bottom: 3px solid var(--bs-warning-border-subtle);
}

.card-footer {
    padding: 0.5rem 1rem;
    color: var(--text-light-color);
    background-color: var(--primary-color);
    text-align: center;
}

@media (min-width: 768px) {
    .col-md-small {
        flex: 0 0 auto;
        width: 10%;
    }
}

.modal-header {
    color: var(--text-light-color);
    background-color: var(--primary-color);
}

.py-eighth {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}

/*
    ASP Net Core validation overrides.
    These copy the bootstrap validation styles to the ASPNetCore validation classes.
*/
.form-control.input-validation-error {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.input-validation-error:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

textarea.form-control.input-validation-error {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.form-select.input-validation-error {
    border-color: var(--bs-form-invalid-border-color);
}

.form-select.input-validation-error:not([multiple]):not([size]),
.form-select.input-validation-error:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    padding-right: 4.125rem;
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.input-validation-error:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.form-control-color.input-validation-error {
    width: calc(3rem + calc(1.5em + 0.75rem));
}

.form-check-input.input-validation-error {
    border-color: var(--bs-form-invalid-border-color);
}

.form-check-input.input-validation-error:checked {
    background-color: var(--bs-form-invalid-color);
}

.form-check-input.input-validation-error:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.form-check-input.input-validation-error ~ .form-check-label {
    color: var(--bs-form-invalid-color);
}

.input-group > .form-control:not(:focus).input-validation-error,
.input-group > .form-select:not(:focus).input-validation-error,
.input-group > .form-floating:not(:focus-within).input-validation-error {
    z-index: 4;
}

.input-validation-valid ~ .valid-feedback,
.input-validation-valid ~ .valid-tooltip {
    display: block;
}

.input-validation-error ~ .invalid-feedback,
.input-validation-error ~ .invalid-tooltip {
    display: block;
}

/*.breadcrumb {
    background-color: transparent;
    margin-top: 1rem;
}

.ceo-main-title {
    margin: 0 0 0.692em;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    font-weight: 700;
    font-style: normal;
    text-transform: none;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 0px;
    color: #000000;
}


.ceo-title-line {
    display: block;
    clear: both;
    margin-bottom: 32px;
    background-color: #702a57;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 2px;
}

.examplesOfGuaranteedIncomePrograms {
    font-size: .875rem;
    padding: 5px 0;
}

label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.7px;
}

.custom-range::-webkit-slider-thumb, .custom-range::-webkit-slider-thumb:active {
    background: #702a57;
}

.custom-range::-moz-range-thumb, .custom-range::-moz-range-thumb:active {
    background: #702a57;
}

.custom-range::-ms-thumb, .custom-range::-ms-thumb:active {
    background: #702a57;
}
*/

/*.ceo-button {*/
/*    -webkit-tap-highlight-color: rgba(0,0,0,0);*/
/*    width: 100%;*/
/*    font-weight: 700 !important;*/
/*    font-style: normal;*/
/*    font-size: 13px !important;*/
/*    text-transform: uppercase;*/
/*    letter-spacing: 0.7px;*/
/*    background-color: #702a57;*/
/*    color: #ffffff;*/
/*    outline: 0;*/
/*    text-align: center;*/
/*    cursor: pointer;*/
/*    border: 1px solid transparent;*/
/*    box-sizing: border-box;*/
/*    word-wrap: break-word;*/
/*    -webkit-appearance: none;*/
/*    -webkit-user-select: none;*/
/*    -moz-user-select: none;*/
/*    -ms-user-select: none;*/
/*    user-select: none;*/
/*    text-decoration: none;*/
/*    position: relative;*/
/*    overflow: hidden;*/
/*    -webkit-transition: color .5s ease, background-color .5s ease, border-color .5s ease;*/
/*    -moz-transition: color .5s ease, background-color .5s ease, border-color .5s ease;*/
/*    -o-transition: color .5s ease, background-color .5s ease, border-color .5s ease;*/
/*    -ms-transition: color .5s ease, background-color .5s ease, border-color .5s ease;*/
/*    transition: color .5s ease, background-color .5s ease, border-color .5s ease;*/
/*    -ms-touch-action: manipulation;*/
/*    touch-action: manipulation;*/
/*    border-radius: 0;*/
/*    z-index: 2;*/
/*    vertical-align: top;*/
/*    display: inline-block;*/
/*    margin: 0;*/
/*    padding: 0 1.538em;*/
/*    line-height: 50px;*/
/*}*/
/*.ceo-button:hover {*/
/*    background-color: #000000;*/
/*    color: #ffffff;*/
/*}*/

/*.custom-control-input:checked ~ .custom-control-label::before {*/
/*    border-color: #702a57;*/
/*    background-color: #702a57;*/
/*}*/

/*.goog-te-gadget .goog-te-combo {*/
/*    display: block;*/
/*    width: 100%;*/
/*    height: calc(1.5em em + 0.75rem + 2px);*/
/*    padding: 0.375rem 0.75rem;*/
/*    font-size: 2rem;*/
/*    font-weight: 400;*/
/*    line-height: 1.5;*/
/*    color: #495057;*/
/*    background-color: #fff;*/
/*    background-clip: padding-box;*/
/*    border: 1px solid #ced4da;*/
/*    border-radius: 0.25rem;*/
/*    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;*/
/*}*/

/*#google_translate_element a {*/
/*    color: #ffffff;*/
/*}*/

/*@media (max-width: 576px) {*/
/*    #google_translate_element {*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*.goog-te-gadget {*/
/*    color: #fff !important;*/
/*}*/

/*.goog-logo-link, .goog-logo-link:link, .goog-logo-link:visited, .goog-logo-link:hover, .goog-logo-link:active {*/
/*    color: #fff !important;*/
/*}*/